import React, { useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import ProfileCard from '../../components/Fitness/profileCard/profileCard';
import Stats from '../../components/Fitness/statsCards/stats';
import BlockCard from '../../components/Fitness/blockCard/blockCard';
import TrainBlock from '../../components/Fitness/trainBlock/TrainBlock';
import SpecialOffer from '../../components/Fitness/statsCards/SpecialOffer';

interface UserData {
  username: string;
  order_id: string;
  user_photo: string;
  phone: string;
  weight: string;
  height: string;
}

interface TrainData {
  photos: string[];
  title: string;
  date: string;
  completed: string;
  exercises_complex: string;
  course_id: string;
}

interface OrderData {
  message: string;
  status: string;
  order_id: string;
}

const Fitness: React.FC = () => {
  const [data, setData] = useState<UserData | undefined>();
  const [trainData, setTrainData] = useState<TrainData[] | undefined>();
  const [activeSection, setActiveSection] = useState<'profile' | 'nutrition' | 'training'>('profile');
  const [showFirstPopup, setShowFirstPopup] = useState<boolean>(true);
  const [showSecondPopup, setShowSecondPopup] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<OrderData | undefined>();

  useEffect(() => {
    const user_id = localStorage.getItem('user_id');

    if (user_id) {
      Promise.all([
        fetch(`https://bodydeo.pw:8000/user_info?user_id=${user_id}`),
        fetch(`https://bodydeo.pw:8000/train?user_id=${user_id}`),
        fetch(`https://bodydeo.pw:8000/order_info?user_id=${user_id}`),
      ])
        .then(async ([userInfoResponse, trainResponse, orderResponse]) => {
          if (userInfoResponse.ok && trainResponse.ok && orderResponse.ok) {
            const userInfoData = await userInfoResponse.json();
            setData(userInfoData);
            const trainData = await trainResponse.json();
            setTrainData(trainData);
            const orderInfo = await orderResponse.json();
            setOrderData(orderInfo);
            setShowFirstPopup(false);

            if (orderInfo.status !== '5' && orderInfo.status !== '6') {
              setShowSecondPopup(true);
            }
          } else {
            console.error('Ошибка при отправке одного из запросов');
          }
        })
        .catch((error) => {
          console.error('Ошибка при отправке запросов:', error);
        });
    }
  }, []);

  const handleCloseSecondPopup = () => {
    setShowSecondPopup(false);
  };

  const handleSectionChange = (section: 'profile' | 'nutrition' | 'training') => {
    setActiveSection(section);
  };

  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-gray-900'>
      {showFirstPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg flex items-center">
            <FaSpinner className="animate-spin text-blue-500" />
            <p className='ml-2'>Загрузка...</p>
          </div>
        </div>
      )}
      {showSecondPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg">
            <h2 className="text-lg font-semibold mb-2">Информация по заказу №{orderData?.order_id}</h2>
            <p className="text-gray-700 mb-4" dangerouslySetInnerHTML={{ __html: orderData?.message || "" }}></p>
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
              onClick={handleCloseSecondPopup}
            >
              Закрыть
            </button>
          </div>
        </div>
      )}
      <div className='max-w-3xl w-full mx-auto grid gap-4 grid-cols-1'>
{activeSection === 'profile' && data && (
                <>
                <ProfileCard
                          fio={data.username}
                          orderId={data.order_id}
                          image={data.user_photo}
                          weight={data.weight}
                          height={data.height}
                        />
                <Stats />
                <div style={{paddingBottom:'45px'}}>
               
                </div>
                </>
                )}
                {activeSection === 'nutrition' && (
              
                <BlockCard  />
    
                )}
                {activeSection === 'training' && trainData && (
                <div className='flex flex-col space-y-4'>
<div className="w-full mb-2 rounded-lg relative">
  <img src="tren.jpg" alt="Баннер курса питания" className="max-w-full h-auto shadow p-2"/>
</div>
<h3 className="text-center text-white font-serif text-2xl">Курс тренировок на месяц!</h3>

                <div style={{paddingBottom:'80px'}}>
                {trainData.map((block, index) => (
                <TrainBlock
                            key={index}
                            photos={block.photos}
                            title={block.title}
                            date={block.date}
                            completed={block.completed}
                            exercises_complex={block.exercises_complex}
                            course_id={block.course_id}
                          />
                ))}
                </div>
                </div>
                )}
                </div>
                
              <div className="fixed bottom-0 left-0 w-full bg-white border-t border-gray-200 z-10 p-1 rounded-t-lg">
  <div className="flex justify-between">
    <div className="flex-1 group mx-2">
      <a onClick={() => handleSectionChange('profile')} className="flex items-end justify-center text-center mx-auto px-4 pt-2 w-full text-gray-400 group-hover:text-indigo-500 border-b-2 border-transparent group-hover:border-indigo-500">
       
        <span className="block px-1 flex flex-col items-center">
        <img width="32" height="32" className=' pt-1 mb-1 block' src="https://img.icons8.com/pastel-glyph/64/person-male--v2.png" alt="person-male--v2"/>
          <span className="block text-xs pb-1">Профиль</span>
        </span>
      </a>
    </div>
    <div className="flex-1 group mx-2">
      <a onClick={() => handleSectionChange('nutrition')} className="flex items-end justify-center text-center mx-auto px-4 pt-2 w-full text-gray-400 group-hover:text-indigo-500 border-b-2 border-transparent group-hover:border-indigo-500">
      <span className="block px-1 flex flex-col items-center">
      <img width="32" height="32" className='pt-1 mb-1 block' src="https://img.icons8.com/wired/64/organic-food.png" alt="organic-food"/>
          <span className="block text-xs pb-1">Питание</span>
        </span>
      </a>
    </div>
    <div className="flex-1 group mx-2">
      <a onClick={() => handleSectionChange('training')} className="flex items-end justify-center text-center mx-auto px-4 pt-2 w-full text-gray-400 group-hover:text-indigo-500 border-b-2 border-transparent group-hover:border-indigo-500">
      <span className="block px-1 flex flex-col items-center">
      <img width="32" height="32" src="https://img.icons8.com/ios/50/functional-training.png" alt="functional-training"  className='pt-1 mb-1 block'/>
          <span className="block text-xs pb-1">Тренировка</span>
        </span>
      </a>
    </div>
  </div>
</div>

                </div>
                );
                };

export default Fitness;
