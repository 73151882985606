import React, { useState } from 'react';

import { useNavigate } from "react-router-dom";
import 'react-input-range/lib/css/index.css';
const Questions: React.FC = () => {
    const navigate = useNavigate(); 
    const [weight, setWeight] = useState(0); // Начальное значение веса
    const [height, setHeight] = useState(0); // Начальное значение роста
    const [gender, setGender] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const user_id = localStorage.getItem('user_id')
    const handleGenderSelection = (selectedGender: string) => {
        setGender(selectedGender);
        setCurrentStep(2);
    };

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    
        const form = e.target as HTMLFormElement;
        const enteredHeight = parseFloat(form.height.value);
        const enteredWeight = parseFloat(form.weight.value);
    
        setHeight(enteredHeight);
        setWeight(enteredWeight);
        setIsProcessing(true);
    
        try {
           
            const response = await fetch('https://bodydeo.pw:8000/add_userInfo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    weight: enteredWeight,
                    height: enteredHeight,
                    gender: gender,
                    user_id:user_id
                }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to send data to server');

            }
            setTimeout(() => {
                navigate('/fitness'); 
            }, 3000); 
        
        
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    

    return (
        <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center">
            {currentStep === 1 && (
               <div className="grid grid-cols-2 lg:grid-cols-2 gap-8" style={{ padding: '50px' }}>
                    <div className="p-4 lg:p-12 rounded-2xl overflow-hidden bg-blue-50">
                        <div className="flex items-center text-blue-500">
                            <p className="text-sm font-bold uppercase">
                                Мужчина
                            </p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 ml-2"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                            </svg>
                        </div>
                        <div className="mt-12 flex justify-center items-center hover:scale-125 transform ease-in-out duration-150 transition-transform">
                            <div className="w-full h-full">
                                <img className="w-full h-full object-cover" src="/man.png" alt="" onClick={() => handleGenderSelection('man')} />
                            </div>
                        </div>
                    </div>
                    <div className="p-4 lg:p-12 rounded-2xl overflow-hidden bg-pink-50">
                        <div className="flex items-center text-pink-500">
                            <p className="text-sm font-bold uppercase">
                                Женщина
                            </p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 ml-2"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                            </svg>
                        </div>
                        <div className="mt-12 flex justify-center items-center hover:scale-125 transform ease-in-out duration-150 transition-transform">
                            <div className="w-full h-full">
                                <img className="w-full h-full object-cover" src="/woman.png" alt="" onClick={() => handleGenderSelection('woman')} />
                            </div>
                        </div>
                    </div>
                </div>
            )}

{currentStep === 2 && (
                <div className="min-h-screen flex items-center justify-center">
                    <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-lg">
                     
                        <h1 className="text-2xl font-semibold text-center text-gray-500 mt-8 mb-6">Расчитать индивидуальный курс</h1>
                        <form onSubmit={handleFormSubmit}>
                            <div className="mb-6">
                                <label htmlFor="height" className="block mb-2 text-sm text-gray-600">Введите рост:</label>
                                <input type="number" id="height" name="height" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-500" required />
                            </div>
                            <div className="mb-6">
                                <label htmlFor="weight" className="block mb-2 text-sm text-gray-600">Введите вес:</label>
                                <input type="number" id="weight" name="weight" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-500" required />
                            </div>
                            {isProcessing ? (
                                <button type="button" className="mx-auto bg-indigo-500 text-white px-4 py-2 rounded-lg flex items-center justify-center" disabled>
                                    <div
                                className="m-2 inline-block animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                role="status">
                                <span
                                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                    >Loading...</span>
                                
                                </div>
                                    В обработке...
                                </button>
                            ) : (
                                <button type="submit" className="w-42 p-5 bg-gradient-to-r from-cyan-400 to-cyan-600 text-white py-2 rounded-lg mx-auto block focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 mt-4 mb-6">Расчитать курс тренировок</button>
                            )}
                        </form>
                    </div>
                </div>
            )}



        </div>
    );
};

export default Questions;
