import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { usePWAInstall } from 'react-use-pwa-install'
import InputMask from 'react-input-mask';

const SecondLoginComponent: React.FC = () => {
  const [isLogin, setIsLogin] = useState(true); 
  const install = usePWAInstall();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState(''); // State for the name field
  const navigate = useNavigate(); 

  const handleLogin = async () => {
    try {
      const response = await fetch('https://bodydeo.pw:8000/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber }),
      });

      if (response.ok) {
        console.log('Login successful');
        const data = await response.json();
        const { user_id, isSended } = data;
        localStorage.setItem('user_id', user_id);

        if (isSended === 1) {
          navigate('/fitness');
        } else {
          navigate('/questions');
        }
      } else {
        console.error('Login failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleRegister = async () => {
    try {
      const response = await fetch('https://bodydeo.pw:8000/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, phoneNumber }),
      });

      if (response.ok) {
        console.log('Registration successful');
        const data = await response.json();
        

        if ('isRegistered' in data && data.isRegistered === true) {
           alert('Вы уже проходи регистрация, пройдите авторизацию!')
        } else if ('Registered' in data && data.Registered === true) {
          alert('Поздравляю вы успешно прошли регистрацию!')
        } else {
          alert('Техническая ошибка, просим вас повторить регистрацию через некоторое время')
        }


      } else {
        console.error('Registration failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className="h-auto flex justify-center items-center">
        <div className="w-full max-w-lg bg-white rounded-lg shadow-lg p-8">
          <h1 className="text-3xl font-bold text-center mb-4 cursor-pointer">
            {isLogin ? 'Войти в личный кабинет' : 'Регистрация'}
          </h1>
          {!isLogin && (
            <input
              type="text"
              placeholder="Имя"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="block text-sm py-3 px-4 rounded-lg w-full border outline-none mb-4"
            />
          )}
          <InputMask
            mask="+79999999999"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="block text-sm py-3 px-4 rounded-lg w-full border outline-none mb-8"
            placeholder="+7 778 777 77 77"
          />
          <button 
            className="w-full py-2 text-xl text-white bg-purple-600 rounded-lg hover:bg-purple-700 transition-all"
            onClick={isLogin ? handleLogin : handleRegister}
          >
            {isLogin ? 'Войти в свой кабинет' : 'Зарегистрироваться'}
          </button>
          <button
            className="text-sm mb-2 text-purple-600 hover:underline cursor-pointer mt-5 w-full text-center"
            onClick={() => setIsLogin((prev) => !prev)}
          >
            {isLogin ? 'Перейти к регистрации' : 'Войти в учетную запись'}
          </button>
          {install && <button className="mt-4" onClick={install}>Install</button>}
        </div>
      </div>
    </>
  );
};

export default SecondLoginComponent;
