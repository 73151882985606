import React, { useState } from 'react';
import { FaCarrot } from 'react-icons/fa'; // Импортируем иконку морковки для примера
import nutritionData from './nutrition';
import { FaSun, FaAppleAlt, FaMoon, FaFire } from 'react-icons/fa'; 
interface Ingredient {
  name: string;
  grams: number;
  calories: number;
}

interface FoodItem {
  name: string;
  totalCalories: number; 
  ingredients: Ingredient[];
}

interface NutritionData {
  morning: FoodItem[];
  noon: FoodItem[];
  evening: FoodItem[];
}

const IngredientComponent: React.FC<{ ingredient: Ingredient }> = ({ ingredient }) => (
  <div className="text-sm text-gray-700">
    {ingredient.name}: {ingredient.grams}г ({ingredient.calories} ккал)
  </div>
);

const FoodItemComponent: React.FC<{ item: FoodItem }> = ({ item }) => (
  <div className="my-4 p-4 bg-white rounded-lg shadow-lg border border-gray-200">
    <div className="flex justify-between items-center">
      <FaCarrot className="w-6 h-6 mr-2 text-orange-500" />
      <div>
        <span className="font-semibold">{item.name}</span>
        <div className="text-xs text-gray-600">Всего калорий: {item.totalCalories}</div>
      </div>
    </div>
    <div className="mt-2">
      {item.ingredients.map((ingredient, index) => (
        <IngredientComponent key={index} ingredient={ingredient} />
      ))}
    </div>
  </div>
);

const NutritionBlock: React.FC<{ day: number, data: NutritionData, onSelect: () => void, isSelected: boolean }> = ({ day, data, onSelect, isSelected }) => {
  const totalCalories = data.morning.concat(data.noon, data.evening).reduce((total, currentItem) => total + currentItem.totalCalories, 0);

  return (
    <div 
     style={{width:'100%', margin:'30px'}}
      className={`cursor-pointer p-3 m-2 rounded-2xl shadow-md transform transition duration-500 ease-in-out ${isSelected ? 'm-12 scale-105 bg-gradient-to-r from-purple-500 to-pink-500 border border-purple-300 shadow-2xl' : 'bg-gradient-to-r from-gray-100 to-gray-200 hover:shadow-lg border border-gray-300'} `}
      onClick={onSelect}
    >
      <div className="flex justify-between items-center mb-4">
        <p className={`font-bold text-xl ${isSelected ? 'text-white' : 'text-gray-800'}`}>День {day + 1}</p>
        <span className={`flex items-center font-semibold ${isSelected ? 'text-white' : 'text-purple-600'}`}>
          <FaFire className="mr-2" />{totalCalories} ккал
        </span>
      </div>
      {!isSelected && (
        <div className="flex justify-around text-gray-400">
          <IconHoverAnimation icon={<FaSun />} />
          <IconHoverAnimation icon={<FaAppleAlt />} />
          <IconHoverAnimation icon={<FaMoon />} />
        </div>
      )}
      {isSelected && (
        <div className="space-y-6">
          <div className="bg-white bg-opacity-90 backdrop-filter backdrop-blur-xl rounded-lg p-4 shadow-lg">
            <h3 className="font-bold text-lg mb-2 text-gray-800">Утро:</h3>
            {data.morning.map((item, index) => (
              <FoodItemComponent key={index} item={item} />
            ))}
          </div>
          <div className="bg-white bg-opacity-90 backdrop-filter backdrop-blur-xl rounded-lg p-4 shadow-lg">
            <h3 className="font-bold text-lg mb-2 text-gray-800">День:</h3>
            {data.noon.map((item, index) => (
              <FoodItemComponent key={index} item={item} />
            ))}
          </div>
          <div className="bg-white bg-opacity-90 backdrop-filter backdrop-blur-xl rounded-lg p-4 shadow-lg">
            <h3 className="font-bold text-lg mb-2 text-gray-800">Вечер:</h3>
            {data.evening.map((item, index) => (
              <FoodItemComponent key={index} item={item} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

// Компонент для добавления анимации при наведении на иконку
const IconHoverAnimation: React.FC<{ icon: JSX.Element }> = ({ icon }) => (
  <div className="transition duration-300 ease-in-out transform hover:scale-125">
    {icon}
  </div>
);
const BlockCard: React.FC = () => {
  
  const [selectedDay, setSelectedDay] = useState<number | null>(null);

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <h1 className="text-2xl font-bold mb-4" style={{color: 'white'}}>Курс питания на месяц</h1>
      <div className="w-full mb-4">
        <img src="course3.jpeg" alt="Баннер курса питания" className="max-w-full h-auto rounded-lg shadow"/>
      </div>
      <div className="flex flex-col items-center w-full" style={{paddingBottom: '50px'}}>
        {nutritionData.map((data, index) => (
          <NutritionBlock 
            key={index} 
            day={index} 
            data={data} 
            onSelect={() => setSelectedDay(index)} 
            isSelected={selectedDay === index}
          />
        ))}
      </div>
    </div>
  );
  
        }
export default BlockCard;
