// ./App.js

import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom"
import Home from "./pages/Home/Home"
import Login from "./pages/Login/Login"
import Fitness from "./pages/Fitness/Fitness"
import Exercises from "./pages/Exercises/Exercises";
import Questions from "./pages/Questions/Questions";
import Privacy from "./pages/Privacy/Privacy";
function App() {



function PrivateRoute({ children }: { children: React.ReactNode }) {
  const isAuthenticated = Boolean(localStorage.getItem('user_id'));
  console.log('isAuthenticated:', isAuthenticated);
  if (isAuthenticated) {
    return <>{children}</>;
  } else {
  
    return <Navigate to="/login" />;
  }
  
}


  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/questions",
      element: <Questions />,
    },
    {
      path: "/fitness",
      element: (
        <PrivateRoute>
          <Fitness />
        </PrivateRoute>
      ),
    },
    {
      path: "/fitness/:course_id/:exercises_complex",
      element: (
        <PrivateRoute>
          <Exercises />
        </PrivateRoute>
      ),
    },
  ])

  return (
      <RouterProvider router={router} />
  )
}

export default App