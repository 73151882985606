import React, { useState, useEffect } from 'react';
import StatCard from './statsCard';



const Stats: React.FC = () => {
  const [trainingDays, setTrainingDays] = useState<number>(0);
  const [exercisesDone, setExercisesDone] = useState<number>(0);

  useEffect(() => {
    // Отправка запроса на сервер для получения статистики
    fetchStats();
  }, []); // Пустой массив зависимостей, чтобы выполнить запрос только один раз при монтировании компонента

  const fetchStats = async () => {
    const user_id = localStorage.getItem('user_id');
    if (!user_id) {
      console.error('user_id не найден в localStorage');
      return;
    }
  
    try {
      const response = await fetch(`https://bodydeo.pw:8000/stats?user_id=${user_id}`);
      if (response.ok) {
        const data = await response.json();
        setTrainingDays(data.trainingDays);
        setExercisesDone(data.exercises_done);
      } else {
        console.error('Ошибка при получении статистики');
      }
    } catch (error) {
      console.error('Ошибка при отправке запроса:', error);
    }
  };
  
  return (
    <div className="grid gap-4">
      <StatCard 
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14 absolute bottom-4 right-3 text-green-400" viewBox="0 0 20 20" fill="currentColor">
            <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
            <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
          </svg>
        }
        value={trainingDays}
        label="день тренировок"
      />
      <StatCard 
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14 absolute bottom-4 right-3 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
          </svg>
        }
        value={exercisesDone}
        label="упражнении сделано"
      />

      
    </div>
  );
};

export default Stats;