
import { useNavigate } from "react-router-dom";

import React, { useState } from 'react';
import './Header.css';

const Header: React.FC = () => {

   const navigate = useNavigate(); 
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    const handleLoginClick = () => {
     navigate('/login')
    };

  return (
    <>
 
 <header>
  
      <nav className="shadow">
        <div className="flex justify-between items-center py-6 px-10 container mx-auto">
          <div>
            <h1 className="text-2xl font-bold bg-gradient-to-tr from-indigo-600 to-green-600 bg-clip-text text-transparent hover:cursor-pointer">BodyBalance</h1>
          </div>
          <div className="hidden md:flex md:space-x-4">
            <a href="#main" className="text-gray-600 hover:text-gray-900">Главная</a>
            <a href="#aboutus" className="text-gray-600 hover:text-gray-900">О нас</a>
            <a href="#course" className="text-gray-600 hover:text-gray-900">Курсы</a>
          </div>
          <div className="block md:hidden">
          <button className="text-gray-600 hover:text-gray-900 focus:outline-none" onClick={handleLoginClick}>
  Авторизация
</button>
        </div>
          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-gray-600 hover:text-gray-900 focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>
          {isMenuOpen && (
            <div className="md:hidden absolute top-16 left-0 bg-white w-full p-4 z-10">
              <a href="#main" className="block py-2 text-gray-600 hover:text-gray-900">Главная</a>
              <a href="#aboutus" className="block py-2 text-gray-600 hover:text-gray-900">О нас</a>
              <a href="#course" className="block py-2 text-gray-600 hover:text-gray-900">Курсы</a>
            </div>
          )}
  <div className="md:block hidden sm:block">
  <button onClick={handleLoginClick} className="text-gray-600 hover:text-gray-900 focus:outline-none">Авторизация</button>
</div>



        </div>
      </nav>
    </header>
  
      <main>
       
        <section id="main">
          
          <div className=" sm:px-4 py-6 min-h-full lg:space-y-6 sm:space-y-0 sm:gap-4">
            
          <div className="relative bg-desktop h-96 col-span-4 bg-gradient-to-tr rounded-md flex items-center animate__animated animate__fadeIn sm:bg-mobile md:bg-tablet lg:bg-desktop" >
           <div className="bottom-5 absolute bottom-0 left-1/2 transform -translate-x-1/2">
              <a onClick={handleLoginClick} className="uppercase inline-block mt-8 text-sm bg-white py-2 px-4 rounded font-semibold hover:bg-indigo-100">Начать тренировку</a>
           </div>
          </div>

          </div>
        </section>
      </main>
    </>
  );
};

export default Header;
