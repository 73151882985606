import React from 'react';

const Course: React.FC = () => {
  return (
    <section className="text-gray-600 body-font" id="course">
    <div className="container px-5 py-24 mx-auto">
      <div className="flex flex-col">
        <div className="h-1 bg-gray-200 rounded overflow-hidden">
          <div className="w-24 h-full bg-indigo-500"></div>
        </div>
        <div className="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
          <h1 className="sm:w-2/5 text-gray-900 font-medium title-font text-2xl mb-2 sm:mb-0">Уникальный курс тренировок с BodyBalance от наших лучших экспертов</h1>
          <p className="sm:w-3/5 leading-relaxed text-base sm:pl-10 pl-0">Мы внедряем инновационные методики тренировок с использованием принципов BodyBalance, что позволяет добиться оптимального баланса между силой, гибкостью и психологическим благополучием.</p>
        </div>
      </div>
      <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
        <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
          <div className="rounded-lg h-64 overflow-hidden">
            <img alt="content" className="object-cover object-center h-full w-full" src="ddd.jpeg" />
          </div>
          <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Персонализация программы</h2>
          <p className="text-base leading-relaxed mt-2">Наши эксперты учитывают ваши индивидуальные цели, уровень подготовки и особенности организма, разрабатывая уникальную программу тренировок, идеально подходящую именно вам.</p>
         
        </div>
        <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
          <div className="rounded-lg h-64 overflow-hidden">
            <img alt="content" className="object-cover object-center h-full w-full" src="course2.jpeg" />
          </div>
          <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Комплексный подход</h2>
          <p className="text-base leading-relaxed mt-2">Мы предлагаем комплексный подход к тренировкам, включающий в себя различные виды упражнений, кардио-нагрузки, силовые тренировки и элементы релаксации.</p>
          
        </div>
        <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
          <div className="rounded-lg h-64 overflow-hidden">
            <img alt="content" className="object-cover object-center h-full w-full" src="course3.jpeg" />
          </div>
          <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Постоянная поддержка</h2>
          <p className="text-base leading-relaxed mt-2">Наши эксперты всегда на связи, готовы помочь, ответить на ваши вопросы и дать советы, обеспечивая максимальную мотивацию и поддержку на протяжении всего курса.</p>
         
        </div>
      </div>
    </div>
  </section>
  );
};

export default Course;
