import React from 'react';
import FirstLoginComponent from '../../components/Login/firstComponent/FirstLoginComponent';
import SecondLoginComponent from '../../components/Login/SecondLoginComponent/SecondLoginComponent';

const Login: React.FC = () => {
  return (
    <div className='min-h-screen bg-purple-400 flex flex-col justify-center items-center p-5'>
             <SecondLoginComponent />
        <FirstLoginComponent/>
   </div>
  );
};

export default Login;
