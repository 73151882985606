import React, { useState, useEffect } from 'react';

// Определение типов для пропсов
type ProgressBarProps = {
  totalSets: number;
  workTime: number;
  restTime: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ totalSets, workTime, restTime }) => {
  const [currentSet, setCurrentSet] = useState<number>(1);
  const [currentTime, setCurrentTime] = useState<number>(workTime);
  const [maxTime, setMaxTime] = useState<number>(workTime);
  const [isWorking, setIsWorking] = useState<boolean>(true);
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    if (isActive) {
      interval = setInterval(() => {
        setCurrentTime((prevTime) => prevTime - 1);

        if (currentTime === 0) {
          if (isWorking && currentSet < totalSets) {
            setIsWorking(false);
            setCurrentTime(restTime);
            setMaxTime(restTime);
          } else if (!isWorking) {
            setIsWorking(true);
            setCurrentSet((prevSet) => prevSet + 1);
            setCurrentTime(workTime);
            setMaxTime(workTime);
          } else if (isWorking && currentSet === totalSets) {
            setIsActive(false);
          }
        }
      }, 1000);
    } else {
      clearInterval(interval!);
    }

    return () => clearInterval(interval!);
  }, [isActive, currentTime, isWorking, currentSet, totalSets, workTime, restTime]);

  const toggleWorkout = () => {
    setIsActive(!isActive);
  };

  const resetWorkout = () => {
    setIsActive(false);
    setCurrentSet(1);
    setIsWorking(true);
    setCurrentTime(workTime);
    setMaxTime(workTime);
  };

  const progressValue = ((maxTime - currentTime) / maxTime) * 100;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
      <div className="progress-bar-container" style={{ width: '100%', backgroundColor: '#eee', borderRadius: '8px', marginBottom: '20px', overflow: 'hidden' }}>
        <div className="progress-bar" style={{ width: `${progressValue}%`, backgroundColor: isWorking ? '#4CAF50' : '#2196F3', height: '20px', borderRadius: '8px', transition: 'width 1s ease-in-out' }}></div>
      </div>
      <p style={{ width: '100%', textAlign: 'center', fontSize: '18px', marginBottom: '20px' }}>
        Подходы: {currentSet}/{totalSets} <br />
        Время: {currentTime}s (Осталось)   <br />
        Время {isWorking ? 'тренироваться' : 'отдохнуть'}
      </p>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <button onClick={toggleWorkout} style={{ padding: '10px 20px', backgroundColor: isActive ? '#ff5252' : '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', outline: 'none', transition: 'background-color 0.3s' }}>
          {isActive ? 'Остановить тренировку' : 'Начать тренировку'}
        </button>
        <button onClick={resetWorkout} style={{ padding: '10px 20px', backgroundColor: '#ff9800', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', outline: 'none', transition: 'background-color 0.3s' }}>Сбросить</button>
      </div>
    </div>
  );
};

export default ProgressBar;
