import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Home/Header/Header';
import FirstContent from '../../components/Home/FirstContent/FirstContent';
import SecondContent from '../../components/Home/SecondContent/SecondContent';
import FeedBack from '../../components/Home/FeedBack/Feedback';
import Order from '../../components/Home/OrderForm/Order';
import Course from '../../components/Home/Сourse/Course';

const Home: React.FC = () => {
  const navigate = useNavigate(); 

  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    if (userId) {
      navigate('/fitness'); 
    }
  }, [navigate]); 

  return (
    <div>
      <Header />
      <FirstContent />
      <Course />
      <SecondContent />
      <FeedBack />
      <Order />
    </div>
  );
};

export default Home;
