import React, { useEffect, useState } from 'react';



interface ProfileImage {
    image: string | null;
  }
  const ImageComponent: React.FC<ProfileImage> = ({ image }) => {

    const defaultImage = 'dw.png';
    const [imageSrc, setImageSrc] = useState<string>(image ? image : defaultImage);


  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
   
    if (file) {
      const formData = new FormData();
      formData.append('image', file);
      const user_id = localStorage.getItem('user_id');
        if (user_id !== null) {
        formData.append('user_id', user_id);
        } else {
        console.error('user_id не найден в localStorage');
        }

      try {
        const response = await fetch('https://bodydeo.pw:8000/user_photo', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const imageData = await response.json();
          setImageSrc(imageData.img); 
        } else {
          console.error('Ошибка при отправке изображения на сервер');
        }
      } catch (error) {
        console.error('Ошибка при отправке запроса:', error);
      }
    }
  };

  const handleClick = () => {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      (fileInput as HTMLInputElement).click();
    }
  };

  
  return (
    <div>
      <img
        src={imageSrc}
        alt="aji"
        className="w-32 h-32 object-cover rounded-2xl"
        onClick={handleClick}
      />
      <input
        id="fileInput"
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default ImageComponent;
