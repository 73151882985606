import React from 'react';
import { useParams } from 'react-router-dom';

interface ExercisesHeaderProps {
  complexDescription: string;
}

const ExercisesHeader: React.FC<ExercisesHeaderProps> = ({ complexDescription }) => {
  const { exercises_complex } = useParams<{ course_id: string; exercises_complex: string }>();

  return (
    <div className="bg-gray-100 px-4 py-8 flex justify-center items-center">
      <div className="max-w-2xl w-full">
        <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-lg shadow-xl overflow-hidden">
          <div className="p-8">
            <h2 className="text-white text-5xl font-bold mb-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
              {exercises_complex} день
            </h2>
            <p className="text-indigo-100 text-lg capitalize font-light tracking-wider leading-relaxed">
              {complexDescription}
            </p>
          </div>
          <div className="px-8 py-4 bg-black bg-opacity-20">
            <p className="text-indigo-200 text-md">Продолжайте двигаться вперед, улучшайте свои навыки каждый день.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExercisesHeader;
