import { link } from 'fs';
import React from 'react';
import { Link } from 'react-router-dom';
interface TrainBlockProps {
  photos: string[]; // Массив URL фотографий
  title: string; // Заголовок
  date: string; 
  completed: string;
  exercises_complex: string,
  course_id: string
}

const TrainBlock: React.FC<TrainBlockProps> = ({ photos, title, date, completed ,exercises_complex, course_id}) => {
  let linked: string | undefined;

  if (completed == '1' || completed == '2') {
    linked = `/fitness/${course_id}/${exercises_complex}`;
} else {
    linked = '';
}



  return (
    <Link to={linked} className="m-5 flex flex-col p-4 bg-gray-800 border border-gray-800 shadow-md hover:text-green-500 text-gray-400 hover:shadow-lg rounded-2xl transition ease-in duration-500 transform hover:scale-105 cursor-pointer">
      {completed === '3' && (
  <div className='flex absolute right-2 bottom-2'>
    <p style={{fontWeight:'bold'}}>Откроется завтра</p>
    <img className='ml-2' width="24" height="24" src="https://img.icons8.com/material-outlined/24/40C057/open-lock.png" alt="open-lock"/> 
  </div>
)}

      <div className="flex items-center justify-between">
        
        <div className="flex flex-col mr-auto">
          <div className={`-space-x-5 flex ${completed == '0' || completed === '3' ? 'blur' : ''}`}>
            {photos.map((photo, index) => (
              <img
                key={index}
                src={photo}
                alt={`photo-${index}`}
                className="relative p-1 w-12 h-12 object-cover rounded-2xl border-2 border-gray-600 bg-gray-800 shadow"
              />
            ))}
          </div>
          <div className="flex flex-col ml-3 min-w-0">
            <div className="font-medium leading-none text-gray-100">{title}</div>
            <p className="text-sm text-gray-500 leading-none mt-1 truncate">{date}</p>
          </div>
        </div>
        <div className="flex flex-col ml-3 min-w-0">
          {completed == '1' ? (
            

<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 ml-2" viewBox="0 0 20 20" fill="currentColor">
<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
</svg>
          ) : completed =='2'? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
            </svg>
          
          ):
          (

            <img width="24" height="24"
src="https://img.icons8.com/material/96/FA5252/padlock-outline.png" alt="padlock-outline"/>
          )}
        </div>
      </div>
    </Link>
  );
};

export default TrainBlock;

