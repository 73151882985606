import React from 'react';

interface StatProps {
  icon: JSX.Element;
  value: number;
  label: string;
}

const StatCard: React.FC<StatProps> = ({ icon, value, label }) => {
  return (
    <div className="col-span-12 sm:col-span-4">
      <div className={`p-4 relative bg-gray-800 border border-gray-800 shadow-lg rounded-2xl`}>
        <div className="flex justify-between items-center">
          {icon}
        </div>
        <div className="text-2xl text-gray-100 font-medium leading-8 mt-5">{value}</div>
        <div className="text-sm text-gray-500">{label}</div>
      </div>
    </div>
  );
};


export default StatCard;