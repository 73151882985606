import React from 'react';

const FirstContent: React.FC = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap w-full mb-20 ">
          <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900" id="aboutus">Худеем быстро с BodyBalance</h1>
            <div className="h-1 w-20 bg-indigo-500 rounded"></div>
          </div>
          <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">BodyBalance является лучший продуктом компании VitaBalance. Мы поможем Вам похудеть быстро, безопасно и в очень краткий срок.</p>
        </div>
        <div className="flex flex-wrap -m-4">
          <div className="xl:w-1/4 md:w-1/2 p-4">
            <div className="shadow-xl p-6 rounded-lg">
              <img className="h-40 rounded w-full object-cover object-center mb-6" src="firstContent.jpeg"/>
              <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">Преимущества</h3>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-4">Худеем в краткий срок</h2>
              <p className="leading-relaxed text-base">Уникальная формула BodyBalance обеспечивает эффективное снижение веса в кратчайшие сроки.</p>
            </div>
          </div>
          <div className="xl:w-1/4 md:w-1/2 p-4">
            <div className="shadow-xl p-6 rounded-lg">
              <img className="h-40 rounded w-full object-cover object-center mb-6" src="firstContent2.jpeg" alt="content" />
              <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">Преимущества</h3>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-4">Безопасность</h2>
              <p className="leading-relaxed text-base">BodyBalance способствует не только похудению, но и поддерживает общее благополучие организма, помогая вам достичь идеальной фигуры без жертв и усилий.</p>
            </div>
          </div>
          <div className="xl:w-1/4 md:w-1/2 p-4">
            <div className="shadow-xl p-6 rounded-lg">
              <img className="h-40 rounded w-full object-cover object-center mb-6" src="firstContent3.jpeg" alt="content" />
              <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">Преимущества</h3>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-4">100.000 потребителей получили результат</h2>
              <p className="leading-relaxed text-base">Поверьте опыту 100 000 довольных потребителей, достигших результатов благодаря BodyBalance! Наш продукт доказывает свою эффективность, обеспечивая успех в похудении для широкого круга людей.</p>
            </div>
          </div>
          <div className="xl:w-1/4 md:w-1/2 p-4">
            <div className="shadow-xl p-6 rounded-lg">
              <img className="h-40 rounded w-full object-center mb-6" src="firstContent4.jpeg" alt="content" />
              <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">Преимущества</h3>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-4">Свой собственный разработанный курс тренировок</h2>
              <p className="leading-relaxed text-base">Представляем вам уникальный курс тренировок, разработанный специально для достижения ваших фитнес-целей! Наш подход комбинирует эффективные упражнения для сжигания жира, укрепления мышц и повышения выносливости. Что делает наш курс особенным.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FirstContent;
