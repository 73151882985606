import React from 'react';
import ImageComponent from './profileImage';
import Light from './Light';
import { useNavigate } from "react-router-dom";

interface ProfileCardProps {
  fio: string | null;
  orderId: string | null; 
  image: string | null;
  weight:string,
  height:string
}

const ProfileCard: React.FC<ProfileCardProps> = ({ fio, orderId,image,weight,height }) => {

  const navigate = useNavigate(); 
  const isTWA = 'getInstalledRelatedApps' in window.navigator;


  const handleLogout = () => {
    localStorage.removeItem('user_id');
    navigate('/'); 
  };

  return (
    <div className="flex flex-col sticky top-0 z-10">
      
      <div className="bg-gray-800 border border-gray-800 shadow-lg rounded-2xl p-4">


        <div className="flex-none sm:flex">
          <div className=" relative h-32 w-32   sm:mb-0 mb-3">
      

           <ImageComponent image={image}/>
      
            <a
              href="#"
              className="absolute -right-2 bottom-2   -ml-3  text-white p-1 text-xs bg-green-400 hover:bg-green-500 font-medium tracking-wider rounded-full transition ease-in duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-4 w-4"
              >
                <path
                  d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                ></path>
              </svg>
            </a>

            
          </div>

          <div className="flex-auto sm:ml-5 justify-evenly">
            <div className="flex items-center justify-between sm:mt-2">
              <div className="flex items-center">
                <div className="flex flex-col">
                  <div className="w-full flex-none text-lg text-gray-200 font-bold leading-none">
                    {fio}
                  </div>
                  <div className="flex-auto text-gray-400 my-1">
                    <span className="mr-3 ">BodyBalance</span>
                    <span className="mr-3 border-r border-gray-600  max-h-0"></span>
                    <span>Курсы похудения <br/> ID заказа: {orderId}</span>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5 text-yellow-400"
                >
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                  ></path>
                </svg>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5 text-yellow-400"
                >
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                  ></path>
                </svg>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5 text-yellow-400"
                >
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                  ></path>
                </svg>
                
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5 text-yellow-400"
                >
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                  ></path>
                </svg>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5 text-yellow-400"
                >
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                  ></path>
                </svg>
              </div>
              <div className="flex-1 inline-flex items-center ml-2 space-x-2">
                <a href="https://www.behance.net/ajeeshmon" target="_blank">
                  <svg
                    className="cursor-pointer w-5 h-5 p-1 rounded-2xl hover:bg-blue-500 hover:text-white transition ease-in duration-300"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="48"
                    height="48"
                    viewBox="0 0 172 172"
                    style={{ fill: '#4a90e2' }}
                  >
                   
                  </svg>
                </a>
               
              </div>
            </div>
         
            <div className="flex pt-2 text-sm text-gray-400 ">
              <div className="flex-1 inline-flex flex-row-reverse items-center">
                
                <p className="">Вес: {weight} кг</p>
              </div>
              <div className="flex-1 inline-flex flex-row-reverse items-center">
              
                <p className="">Рост: {height} см</p>
              </div>
          


              <div className="flex-1 inline-flex flex-row-reverse items-center">
              
              <img onClick={handleLogout} width="32" height="32" src="https://img.icons8.com/fluency/48/exit--v1.png" alt="exit--v1"/>
  
            </div>
            </div>
          </div>


         
        </div>
       
      </div>
      
    </div>
  );
};

export default ProfileCard;



