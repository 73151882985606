import React, { useEffect, useState } from 'react';
import ExercisesHeader from '../../components/Exercises/Header/ExercisesHeader';
import Exercise from '../../components/Exercises/Exercise/Exercise';
import { useParams } from 'react-router-dom';
const Exercises: React.FC = () => {
    const [exerciseData, setExerciseData] = useState<any[]>([]);
    const [complexDescription, setComplexDescription] = useState<string>("");
    const { course_id, exercises_complex } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const requestOptions = {
                    method: 'POST', 
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ course_id, exercises_complex }) 
                };
                
            
                const response = await fetch('https://bodydeo.pw:8000/exercise', requestOptions);
                const data = await response.json();
                setExerciseData(data.exercise_blocks_data)
                setComplexDescription(data.complex_description)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []); 

      return (
        <>
        <ExercisesHeader complexDescription={complexDescription} />
        <div>
        <Exercise exerciseData={exerciseData} />
        </div>

        </>
    
      );
    };


export default Exercises;
