const nutritionData = [
    // День 1
    {
      morning: [{
        name: "Овсяная каша на воде с яблоком",
        totalCalories: 250,
        ingredients: [
          { name: "Овсяные хлопья", grams: 50, calories: 180 },
          { name: "Яблоко", grams: 100, calories: 50 },
          { name: "Корица", grams: 5, calories: 20 },
        ],
      }],
      noon: [{
        name: "Куриная грудка на пару с брокколи",
        totalCalories: 350,
        ingredients: [
          { name: "Куриная грудка", grams: 150, calories: 165 },
          { name: "Брокколи", grams: 150, calories: 45 },
          { name: "Специи", grams: 5, calories: 0 },
        ],
      }],
      evening: [{
        name: "Творожная запеканка без сахара",
        totalCalories: 200,
        ingredients: [
          { name: "Творог нежирный", grams: 200, calories: 200 },
        ],
      }],
    },
    // День 2
    {
      morning: [{
        name: "Протеиновый смузи",
        totalCalories: 300,
        ingredients: [
          { name: "Протеиновый порошок", grams: 30, calories: 120 },
          { name: "Банан", grams: 100, calories: 90 },
          { name: "Шпинат", grams: 50, calories: 12 },
          { name: "Вода", grams: 200, calories: 0 },
        ],
      }],
      noon: [{
        name: "Салат из киноа с овощами",
        totalCalories: 350,
        ingredients: [
          { name: "Киноа", grams: 50, calories: 180 },
          { name: "Огурец", grams: 100, calories: 16 },
          { name: "Помидоры черри", grams: 100, calories: 18 },
          { name: "Лимонный сок", grams: 20, calories: 6 },
          { name: "Оливковое масло", grams: 10, calories: 90 },
        ],
      }],
      evening: [{
        name: "Гречневая каша с грибами",
        totalCalories: 250,
        ingredients: [
          { name: "Гречка", grams: 100, calories: 92 },
          { name: "Грибы", grams: 100, calories: 22 },
          { name: "Лук", grams: 50, calories: 20 },
          { name: "Специи", grams: 5, calories: 0 },
        ],
      }],
    },
    // День 3
    {
      morning: [{
        name: "Яичница из 2 яиц с овощами",
        totalCalories: 250,
        ingredients: [
          { name: "Яйца", grams: 100, calories: 140 },
          { name: "Перец болгарский", grams: 100, calories: 26 },
          { name: "Томаты", grams: 100, calories: 18 },
          { name: "Зелень", grams: 20, calories: 5 },
        ],
      }],
      noon: [{
        name: "Рыба на пару с кабачковыми оладьями",
        totalCalories: 400,
        ingredients: [
          { name: "Филе рыбы (например, треска)",
          grams: 150, calories: 110 },
          { name: "Кабачок", grams: 200, calories: 60 },
          { name: "Яйцо для оладий", grams: 50, calories: 70 },
          { name: "Мука цельнозерновая", grams: 30, calories: 100 },
          { name: "Специи", grams: 5, calories: 0 },
        ],
      }],
      evening: [{
        name: "Салат из авокадо с креветками",
        totalCalories: 300,
        ingredients: [
          { name: "Авокадо", grams: 100, calories: 160 },
          { name: "Креветки", grams: 100, calories: 99 },
          { name: "Лимонный сок", grams: 10, calories: 3 },
          { name: "Зеленый салат", grams: 50, calories: 8 },
          { name: "Оливковое масло", grams: 10, calories: 90 },
        ],
      }],
    },
    // День 4
    {
      morning: [{
        name: "Каша из киноа с ягодами",
        totalCalories: 300,
        ingredients: [
          { name: "Киноа", grams: 50, calories: 180 },
          { name: "Смесь ягод (замороженные или свежие)", grams: 100, calories: 40 },
          { name: "Миндальные лепестки", grams: 10, calories: 60 },
        ],
      }],
      noon: [{
        name: "Легкий овощной суп с курицей",
        totalCalories: 350,
        ingredients: [
          { name: "Куриный бульон", grams: 500, calories: 100 },
          { name: "Куриная грудка", grams: 100, calories: 165 },
          { name: "Картофель", grams: 100, calories: 77 },
          { name: "Морковь", grams: 50, calories: 21 },
          { name: "Зелень", grams: 20, calories: 5 },
        ],
      }],
      evening: [{
        name: "Салат с фетой и оливками",
        totalCalories: 250,
        ingredients: [
          { name: "Салатные листья", grams: 100, calories: 15 },
          { name: "Фета", grams: 50, calories: 140 },
          { name: "Оливки", grams: 30, calories: 45 },
          { name: "Огурцы", grams: 50, calories: 8 },
          { name: "Помидоры", grams: 50, calories: 9 },
          { name: "Оливковое масло", grams: 10, calories: 90 },
        ],
      }],
    },
    // День 5
    {
      morning: [{
        name: "Тосты с авокадо и яйцом",
        totalCalories: 350,
        ingredients: [
          { name: "Цельнозерновой хлеб", grams: 50, calories: 130 },
          { name: "Авокадо", grams: 50, calories: 80 },
          { name: "Яйцо", grams: 50, calories: 70 },
          { name: "Соль, перец", grams: 5, calories: 0 },
        ],
      }],
      noon: [{
        name: "Гречка с овощами на пару",
        totalCalories: 300,
        ingredients: [
          { name: "Гречка", grams: 100, calories: 92 },
          { name: "Брокколи", grams: 100, calories: 34 },
          { name: "Морковь", grams: 100, calories: 41 },
          { name: "Специи", grams: 5, calories: 0 },
        ],
      }],
      evening: [{
        name: "Легкий куриный салат",
        totalCalories: 300,
        ingredients: [
          { name: "Куриная грудка", grams: 100, calories: 165 },
          { name: "Салатный лист", grams: 50, calories: 7 },
          { name: "Огурец", grams: 50, calories: 8 },
          { name: "Помидор", grams: 50, calories: 11 },
          { name: "Лимонный сок", grams: 10, calories: 3 },
          { name: "Оливковое масло", grams: 10, calories: 90 },
        ],
      }],
    },
    // День 6
    {
      morning: [{
        name: "Омлет с овощами",
        totalCalories: 250,
        ingredients: [
          { name: "Яйца", grams: 100, calories: 140 },
          { name: "Шпинат", grams: 50, calories: 12 },
          { name: "Помидоры", grams: 50, calories: 11 },
          { name: "Перец", grams: 50, calories: 14 },
          { name: "Специи", grams: 5, calories: 0 },
        ],
      }],
      noon: [{
        name: "Теплый салат с киноа и овощами",
        totalCalories: 350,
        ingredients: [
          { name: "Киноа", grams: 50, calories: 180 },
          { name: "Огурец", grams: 50, calories: 8 },
          { name: "Помидоры", grams: 50, calories: 11 },
          { name: "Сладкий перец", grams: 50, calories: 14 },
          { name: "Лимонный сок", grams: 10, calories: 3 },
          { name: "Оливковое масло", grams: 10, calories: 90 },
        ],
      }],
      evening: [{
        name: "Творожная запеканка с ягодами",
        totalCalories: 200,
        ingredients: [
          { name: "Творог нежирный", grams: 200, calories: 160 },
          { name: "Ягоды (любые, по сезону)", grams: 50, calories: 30 },
          { name: "Яйцо", grams: 20, calories: 28 },
        ],
      }],
    },
    // День 7
    {
      morning: [{
        name: "Гречневая каша с фруктами",
        totalCalories: 300,
        ingredients: [
          { name: "Гречка", grams: 100, calories: 92 },
          { name: "Яблоко", grams: 100, calories: 52 },
          { name: "Груша", grams: 100, calories: 57 },
          { name: "Корица", grams: 2, calories: 5 },
        ],
      }],
      noon: [{
        name: "Лосось на гриле с овощным салатом",
        totalCalories: 400,
        ingredients: [
          { name: "Филе лосося", grams: 150, calories: 280 },
          { name: "Смешанные салатные листья", grams: 100, calories: 20 },
          { name: "Оливковое масло", grams: 10, calories: 90 },
          { name: "Лимонный сок", grams: 10, calories: 3 },
        ],
      }],
      evening: [{
        name: "Куриный суп с овощами",
        totalCalories: 250,
        ingredients: [
          { name: "Куриный бульон", grams: 500, calories: 100 },
          { name: "Куриное филе", grams: 100, calories: 110 },
          { name: "Картофель", grams: 100, calories: 77 },
          { name: "Морковь", grams: 50, calories: 21 },
          { name: "Лук", grams: 50, calories: 20 },
        ],
      }],
    },
    // День 8
    {
      morning: [{
        name: "Чиа пудинг с молоком и ягодами",
        totalCalories: 350,
        ingredients: [
          { name: "Семена чиа", grams: 30, calories: 130        },
          { name: "Альтернативное молоко (например, миндальное)", grams: 200, calories: 60 },
          { name: "Ягоды", grams: 100, calories: 40 },
          { name: "Мед (опционально)", grams: 10, calories: 30 },
        ],
      }],
      noon: [{
        name: "Цветная капуста, запеченная с турмериком и чесноком",
        totalCalories: 250,
        ingredients: [
          { name: "Цветная капуста", grams: 300, calories: 75 },
          { name: "Турмерик", grams: 5, calories: 16 },
          { name: "Чеснок порошок", grams: 5, calories: 10 },
          { name: "Оливковое масло", grams: 10, calories: 90 },
        ],
      }],
      evening: [{
        name: "Салат из нута с овощами",
        totalCalories: 300,
        ingredients: [
          { name: "Нут (вареный)", grams: 100, calories: 164 },
          { name: "Огурец", grams: 50, calories: 8 },
          { name: "Помидор", grams: 50, calories: 11 },
          { name: "Красный лук", grams: 30, calories: 12 },
          { name: "Лимонный сок", grams: 10, calories: 3 },
          { name: "Оливковое масло", grams: 10, calories: 90 },
        ],
      }],
    },
    // День 9
    {
      morning: [{
        name: "Овсяноблин с ягодами",
        totalCalories: 300,
        ingredients: [
          { name: "Овсяная мука", grams: 50, calories: 180 },
          { name: "Яйцо", grams: 50, calories: 70 },
          { name: "Ягоды", grams: 100, calories: 40 },
          { name: "Натуральный йогурт", grams: 30, calories: 10 },
        ],
      }],
      noon: [{
        name: "Тушеные овощи с куриной грудкой",
        totalCalories: 350,
        ingredients: [
          { name: "Куриная грудка", grams: 150, calories: 165 },
          { name: "Цукини", grams: 100, calories: 17 },
          { name: "Баклажан", grams: 100, calories: 25 },
          { name: "Томатный соус", grams: 50, calories: 30 },
          { name: "Специи", grams: 5, calories: 0 },
        ],
      }],
      evening: [{
        name: "Салат с киноа и авокадо",
        totalCalories: 320,
        ingredients: [
          { name: "Киноа", grams: 50, calories: 180 },
          { name: "Авокадо", grams: 50, calories: 80 },
          { name: "Черри", grams: 50, calories: 15 },
          { name: "Лимонный сок", grams: 10, calories: 3 },
          { name: "Оливковое масло", grams: 10, calories: 90 },
        ],
      }],
    },
    // День 10
    {
      morning: [{
        name: "Смузи из шпината, банана и протеина",
        totalCalories: 280,
        ingredients: [
          { name: "Шпинат", grams: 50, calories: 12 },
          { name: "Банан", grams: 100, calories: 90 },
          { name: "Протеиновый порошок", grams: 30, calories: 120 },
          { name: "Вода", grams: 200, calories: 0 },
        ],
      }],
      noon: [{
        name: "Легкий салат с тунцом",
        totalCalories: 300,
        ingredients: [
          { name: "Консервированный тунец в собственном соку", grams: 100, calories: 99 },
          { name: "Салатные листья", grams: 50, calories: 7 },
          { name: "Огурец", grams: 50, calories: 8 },
          { name: "Помидор", grams: 50, calories: 11 },
          { name: "Лук красный", grams: 30, calories: 12 },
          { name: "Лимонный сок", grams: 10, calories: 3 },
          { name: "Оливковое масло", grams: 10, calories: 90 },
        ],
      }],
      evening: [{
        name: "Запеченная цветная капуста с специями",
        totalCalories: 200,
        ingredients: [
          { name: "Цветная капуста", grams: 300, calories: 75 },
          { name: "Куркума", grams: 5, calories: 15 },
          { name: "Паприка", grams: 5, calories: 14 },
          { name: "Чеснок порошок", grams: 5, calories: 10 },
          { name: "Оливковое масло", grams: 10, calories: 90 },
        ],
      }],
    },

    // День 13
{
    morning: [{
      name: "Ягодный смузи с овсянкой",
      totalCalories: 300,
      ingredients: [
        { name: "Свежие ягоды (любые)", grams: 100, calories: 40 },
        { name: "Овсянка", grams: 30, calories: 108 },
        { name: "Банан", grams: 50, calories: 45 },
        { name: "Альтернативное молоко (например, миндальное)", grams: 200, calories: 60 },
      ],
    }],
    noon: [{
      name: "Вегетарианские лепешки с овощами и хумусом",
      totalCalories: 350,
      ingredients: [
        { name: "Цельнозерновая лепешка", grams: 50, calories: 130 },
        { name: "Хумус", grams: 50, calories: 78 },
        { name: "Смесь овощей (баклажан, перец, лук)", grams: 150, calories: 60 },
        { name: "Листья салата", grams: 30, calories: 5 },
      ],
    }],
    evening: [{
      name: "Куриный бульон с нудлами и овощами",
      totalCalories: 250,
      ingredients: [
        { name: "Куриный бульон", grams: 500, calories: 40 },
        { name: "Куриное филе", grams: 100, calories: 110 },
        { name: "Цельнозерновые нудлы", grams: 50, calories: 35 },
        { name: "Морковь", grams: 50, calories: 21 },
        { name: "Брокколи", grams: 50, calories: 17 },
      ],
    }],
  },








  // День 14
{
    morning: [{
      name: "Овсянка на воде с медом и орехами",
      totalCalories: 280,
      ingredients: [
        { name: "Овсянка", grams: 50, calories: 180 },
        { name: "Мед", grams: 10, calories: 30 },
        { name: "Грецкие орехи", grams: 20, calories: 131 },
      ],
    }],
    noon: [{
      name: "Салат с куриной грудкой и авокадо",
      totalCalories: 400,
      ingredients: [
        { name: "Куриная грудка", grams: 100, calories: 165 },
        { name: "Авокадо", grams: 50, calories: 80 },
        { name: "Салат айсберг", grams: 50, calories: 7 },
        { name: "Помидор", grams: 50, calories: 11 },
        { name: "Огурец", grams: 50, calories: 8 },
        { name: "Оливковое масло", grams: 10, calories: 90 },
      ],
    }],
    evening: [{
      name: "Запеченный лосось с кабачками и спаржей",
      totalCalories: 300,
      ingredients: [
        { name: "Лосось", grams: 150, calories: 280 },
        { name: "Кабачки", grams: 100, calories: 17 },
        { name: "Спаржа", grams: 100, calories: 20 },
        { name: "Лимонный сок", grams: 10, calories: 3 },
        { name: "Специи", grams: 5, calories: 0 },
      ],
    }],
  },
    

  // День 13
{
    morning: [{
      name: "Смузи с кефиром, бананом и шпинатом",
      totalCalories: 220,
      ingredients: [
        { name: "Кефир низкой жирности", grams: 200, calories: 100 },
        { name: "Банан", grams: 100, calories: 90 },
        { name: "Шпинат", grams: 50, calories: 12 },
        { name: "Мед (по желанию)", grams: 10, calories: 30 },
      ],
    }],
    noon: [{
      name: "Цельнозерновой паста с овощами",
      totalCalories: 350,
      ingredients: [
        { name: "Цельнозерновая паста", grams: 100, calories: 140 },
        { name: "Цукини", grams: 100, calories: 17 },
        { name: "Баклажан", grams: 100, calories: 24 },
        { name: "Томатный соус", grams: 100, calories: 40 },
        { name: "Сыр пармезан (по желанию)", grams: 20, calories: 80 },
      ],
    }],
    evening: [{
      name: "Куриный бульон с овощами",
      totalCalories: 150,
      ingredients: [
        { name: "Куриный бульон", grams: 500, calories: 50 },
        { name: "Морковь", grams: 50, calories: 20 },
        { name: "Картофель", grams: 50, calories: 38 },
        { name: "Зеленый горошек", grams: 50, calories: 42 },
      ],
    }],
  },




  // День 14
{
    morning: [{
      name: "Яичница с авокадо на цельнозерновом тосте",
      totalCalories: 320,
      ingredients: [
        { name: "Яйца", grams: 100, calories: 140 },
        { name: "Авокадо", grams: 50, calories: 80 },
        { name: "Цельнозерновой хлеб", grams: 50, calories: 100 },
      ],
    }],
    noon: [{
      name: "Салат с киноа, помидорами и огурцами",
      totalCalories: 300,
      ingredients: [
        { name: "Киноа", grams: 100, calories: 120 },
        { name: "Помидоры", grams: 100, calories: 18 },
        { name: "Огурцы", grams: 100, calories: 16 },
        { name: "Оливковое масло", grams: 10, calories: 90 },
        { name: "Лимонный сок", grams: 10, calories: 3 },
      ],
    }],
    evening: [{
      name: "Грибной крем-суп",
      totalCalories: 200,
      ingredients: [
        { name: "Грибы", grams: 200, calories: 44 },
        { name: "Лук", grams: 50, calories: 20 },
        { name: "Сливки низкой жирности", grams: 50, calories: 45 },
        { name: "Куриный бульон", grams: 200, calories: 20 },
        { name: "Специи", grams: 5, calories: 0 },
      ],
    }],
  },




  {
    morning: [{
      name: "Овсяноблин с ягодами",
      totalCalories: 300,
      ingredients: [
        { name: "Овсяная мука", grams: 50, calories: 180 },
        { name: "Яйцо", grams: 50, calories: 70 },
        { name: "Ягоды", grams: 100, calories: 40 },
        { name: "Натуральный йогурт", grams: 30, calories: 10 },
      ],
    }],
    noon: [{
      name: "Тушеные овощи с куриной грудкой",
      totalCalories: 350,
      ingredients: [
        { name: "Куриная грудка", grams: 150, calories: 165 },
        { name: "Цукини", grams: 100, calories: 17 },
        { name: "Баклажан", grams: 100, calories: 25 },
        { name: "Томатный соус", grams: 50, calories: 30 },
        { name: "Специи", grams: 5, calories: 0 },
      ],
    }],
    evening: [{
      name: "Салат с киноа и авокадо",
      totalCalories: 320,
      ingredients: [
        { name: "Киноа", grams: 50, calories: 180 },
        { name: "Авокадо", grams: 50, calories: 80 },
        { name: "Черри", grams: 50, calories: 15 },
        { name: "Лимонный сок", grams: 10, calories: 3 },
        { name: "Оливковое масло", grams: 10, calories: 90 },
      ],
    }],
  },
  // День 10
  {
    morning: [{
      name: "Смузи из шпината, банана и протеина",
      totalCalories: 280,
      ingredients: [
        { name: "Шпинат", grams: 50, calories: 12 },
        { name: "Банан", grams: 100, calories: 90 },
        { name: "Протеиновый порошок", grams: 30, calories: 120 },
        { name: "Вода", grams: 200, calories: 0 },
      ],
    }],
    noon: [{
      name: "Легкий салат с тунцом",
      totalCalories: 300,
      ingredients: [
        { name: "Консервированный тунец в собственном соку", grams: 100, calories: 99 },
        { name: "Салатные листья", grams: 50, calories: 7 },
        { name: "Огурец", grams: 50, calories: 8 },
        { name: "Помидор", grams: 50, calories: 11 },
        { name: "Лук красный", grams: 30, calories: 12 },
        { name: "Лимонный сок", grams: 10, calories: 3 },
        { name: "Оливковое масло", grams: 10, calories: 90 },
      ],
    }],
    evening: [{
      name: "Запеченная цветная капуста с специями",
      totalCalories: 200,
      ingredients: [
        { name: "Цветная капуста", grams: 300, calories: 75 },
        { name: "Куркума", grams: 5, calories: 15 },
        { name: "Паприка", grams: 5, calories: 14 },
        { name: "Чеснок порошок", grams: 5, calories: 10 },
        { name: "Оливковое масло", grams: 10, calories: 90 },
      ],
    }],
  },
  {
    morning: [{
      name: "Омлет с овощами",
      totalCalories: 250,
      ingredients: [
        { name: "Яйца", grams: 100, calories: 140 },
        { name: "Шпинат", grams: 50, calories: 12 },
        { name: "Помидоры", grams: 50, calories: 11 },
        { name: "Перец", grams: 50, calories: 14 },
        { name: "Специи", grams: 5, calories: 0 },
      ],
    }],
    noon: [{
      name: "Теплый салат с киноа и овощами",
      totalCalories: 350,
      ingredients: [
        { name: "Киноа", grams: 50, calories: 180 },
        { name: "Огурец", grams: 50, calories: 8 },
        { name: "Помидоры", grams: 50, calories: 11 },
        { name: "Сладкий перец", grams: 50, calories: 14 },
        { name: "Лимонный сок", grams: 10, calories: 3 },
        { name: "Оливковое масло", grams: 10, calories: 90 },
      ],
    }],
    evening: [{
      name: "Творожная запеканка с ягодами",
      totalCalories: 200,
      ingredients: [
        { name: "Творог нежирный", grams: 200, calories: 160 },
        { name: "Ягоды (любые, по сезону)", grams: 50, calories: 30 },
        { name: "Яйцо", grams: 20, calories: 28 },
      ],
    }],
  },
  // День 7
  {
    morning: [{
      name: "Гречневая каша с фруктами",
      totalCalories: 300,
      ingredients: [
        { name: "Гречка", grams: 100, calories: 92 },
        { name: "Яблоко", grams: 100, calories: 52 },
        { name: "Груша", grams: 100, calories: 57 },
        { name: "Корица", grams: 2, calories: 5 },
      ],
    }],
    noon: [{
      name: "Лосось на гриле с овощным салатом",
      totalCalories: 400,
      ingredients: [
        { name: "Филе лосося", grams: 150, calories: 280 },
        { name: "Смешанные салатные листья", grams: 100, calories: 20 },
        { name: "Оливковое масло", grams: 10, calories: 90 },
        { name: "Лимонный сок", grams: 10, calories: 3 },
      ],
    }],
    evening: [{
      name: "Куриный суп с овощами",
      totalCalories: 250,
      ingredients: [
        { name: "Куриный бульон", grams: 500, calories: 100 },
        { name: "Куриное филе", grams: 100, calories: 110 },
        { name: "Картофель", grams: 100, calories: 77 },
        { name: "Морковь", grams: 50, calories: 21 },
        { name: "Лук", grams: 50, calories: 20 },
      ],
    }],
  },
  {
    morning: [{
      name: "Протеиновый смузи",
      totalCalories: 300,
      ingredients: [
        { name: "Протеиновый порошок", grams: 30, calories: 120 },
        { name: "Банан", grams: 100, calories: 90 },
        { name: "Шпинат", grams: 50, calories: 12 },
        { name: "Вода", grams: 200, calories: 0 },
      ],
    }],
    noon: [{
      name: "Салат из киноа с овощами",
      totalCalories: 350,
      ingredients: [
        { name: "Киноа", grams: 50, calories: 180 },
        { name: "Огурец", grams: 100, calories: 16 },
        { name: "Помидоры черри", grams: 100, calories: 18 },
        { name: "Лимонный сок", grams: 20, calories: 6 },
        { name: "Оливковое масло", grams: 10, calories: 90 },
      ],
    }],
    evening: [{
      name: "Гречневая каша с грибами",
      totalCalories: 250,
      ingredients: [
        { name: "Гречка", grams: 100, calories: 92 },
        { name: "Грибы", grams: 100, calories: 22 },
        { name: "Лук", grams: 50, calories: 20 },
        { name: "Специи", grams: 5, calories: 0 },
      ],
    }],
  },
  // День 3
  {
    morning: [{
      name: "Яичница из 2 яиц с овощами",
      totalCalories: 250,
      ingredients: [
        { name: "Яйца", grams: 100, calories: 140 },
        { name: "Перец болгарский", grams: 100, calories: 26 },
        { name: "Томаты", grams: 100, calories: 18 },
        { name: "Зелень", grams: 20, calories: 5 },
      ],
    }],
    noon: [{
      name: "Рыба на пару с кабачковыми оладьями",
      totalCalories: 400,
      ingredients: [
        { name: "Филе рыбы (например, треска)",
        grams: 150, calories: 110 },
        { name: "Кабачок", grams: 200, calories: 60 },
        { name: "Яйцо для оладий", grams: 50, calories: 70 },
        { name: "Мука цельнозерновая", grams: 30, calories: 100 },
        { name: "Специи", grams: 5, calories: 0 },
      ],
    }],
    evening: [{
      name: "Салат из авокадо с креветками",
      totalCalories: 300,
      ingredients: [
        { name: "Авокадо", grams: 100, calories: 160 },
        { name: "Креветки", grams: 100, calories: 99 },
        { name: "Лимонный сок", grams: 10, calories: 3 },
        { name: "Зеленый салат", grams: 50, calories: 8 },
        { name: "Оливковое масло", grams: 10, calories: 90 },
      ],
    }],
  },



  {
    morning: [{
      name: "Овсяная каша на воде с яблоком",
      totalCalories: 250,
      ingredients: [
        { name: "Овсяные хлопья", grams: 50, calories: 180 },
        { name: "Яблоко", grams: 100, calories: 50 },
        { name: "Корица", grams: 5, calories: 20 },
      ],
    }],
    noon: [{
      name: "Куриная грудка на пару с брокколи",
      totalCalories: 350,
      ingredients: [
        { name: "Куриная грудка", grams: 150, calories: 165 },
        { name: "Брокколи", grams: 150, calories: 45 },
        { name: "Специи", grams: 5, calories: 0 },
      ],
    }],
    evening: [{
      name: "Творожная запеканка без сахара",
      totalCalories: 200,
      ingredients: [
        { name: "Творог нежирный", grams: 200, calories: 200 },
      ],
    }],
  },
  // День 2
  {
    morning: [{
      name: "Протеиновый смузи",
      totalCalories: 300,
      ingredients: [
        { name: "Протеиновый порошок", grams: 30, calories: 120 },
        { name: "Банан", grams: 100, calories: 90 },
        { name: "Шпинат", grams: 50, calories: 12 },
        { name: "Вода", grams: 200, calories: 0 },
      ],
    }],
    noon: [{
      name: "Салат из киноа с овощами",
      totalCalories: 350,
      ingredients: [
        { name: "Киноа", grams: 50, calories: 180 },
        { name: "Огурец", grams: 100, calories: 16 },
        { name: "Помидоры черри", grams: 100, calories: 18 },
        { name: "Лимонный сок", grams: 20, calories: 6 },
        { name: "Оливковое масло", grams: 10, calories: 90 },
      ],
    }],
    evening: [{
      name: "Гречневая каша с грибами",
      totalCalories: 250,
      ingredients: [
        { name: "Гречка", grams: 100, calories: 92 },
        { name: "Грибы", grams: 100, calories: 22 },
        { name: "Лук", grams: 50, calories: 20 },
        { name: "Специи", grams: 5, calories: 0 },
      ],
    }],
  },



  {
    morning: [{
      name: "Смузи из шпината, банана и протеина",
      totalCalories: 280,
      ingredients: [
        { name: "Шпинат", grams: 50, calories: 12 },
        { name: "Банан", grams: 100, calories: 90 },
        { name: "Протеиновый порошок", grams: 30, calories: 120 },
        { name: "Вода", grams: 200, calories: 0 },
      ],
    }],
    noon: [{
      name: "Легкий салат с тунцом",
      totalCalories: 300,
      ingredients: [
        { name: "Консервированный тунец в собственном соку", grams: 100, calories: 99 },
        { name: "Салатные листья", grams: 50, calories: 7 },
        { name: "Огурец", grams: 50, calories: 8 },
        { name: "Помидор", grams: 50, calories: 11 },
        { name: "Лук красный", grams: 30, calories: 12 },
        { name: "Лимонный сок", grams: 10, calories: 3 },
        { name: "Оливковое масло", grams: 10, calories: 90 },
      ],
    }],
    evening: [{
      name: "Запеченная цветная капуста с специями",
      totalCalories: 200,
      ingredients: [
        { name: "Цветная капуста", grams: 300, calories: 75 },
        { name: "Куркума", grams: 5, calories: 15 },
        { name: "Паприка", grams: 5, calories: 14 },
        { name: "Чеснок порошок", grams: 5, calories: 10 },
        { name: "Оливковое масло", grams: 10, calories: 90 },
      ],
    }],
  },

  // День 13
{
  morning: [{
    name: "Ягодный смузи с овсянкой",
    totalCalories: 300,
    ingredients: [
      { name: "Свежие ягоды (любые)", grams: 100, calories: 40 },
      { name: "Овсянка", grams: 30, calories: 108 },
      { name: "Банан", grams: 50, calories: 45 },
      { name: "Альтернативное молоко (например, миндальное)", grams: 200, calories: 60 },
    ],
  }],
  noon: [{
    name: "Вегетарианские лепешки с овощами и хумусом",
    totalCalories: 350,
    ingredients: [
      { name: "Цельнозерновая лепешка", grams: 50, calories: 130 },
      { name: "Хумус", grams: 50, calories: 78 },
      { name: "Смесь овощей (баклажан, перец, лук)", grams: 150, calories: 60 },
      { name: "Листья салата", grams: 30, calories: 5 },
    ],
  }],
  evening: [{
    name: "Куриный бульон с нудлами и овощами",
    totalCalories: 250,
    ingredients: [
      { name: "Куриный бульон", grams: 500, calories: 40 },
      { name: "Куриное филе", grams: 100, calories: 110 },
      { name: "Цельнозерновые нудлы", grams: 50, calories: 35 },
      { name: "Морковь", grams: 50, calories: 21 },
      { name: "Брокколи", grams: 50, calories: 17 },
    ],
  }],
},



{
    morning: [{
      name: "Яичница с авокадо на цельнозерновом тосте",
      totalCalories: 320,
      ingredients: [
        { name: "Яйца", grams: 100, calories: 140 },
        { name: "Авокадо", grams: 50, calories: 80 },
        { name: "Цельнозерновой хлеб", grams: 50, calories: 100 },
      ],
    }],
    noon: [{
      name: "Салат с киноа, помидорами и огурцами",
      totalCalories: 300,
      ingredients: [
        { name: "Киноа", grams: 100, calories: 120 },
        { name: "Помидоры", grams: 100, calories: 18 },
        { name: "Огурцы", grams: 100, calories: 16 },
        { name: "Оливковое масло", grams: 10, calories: 90 },
        { name: "Лимонный сок", grams: 10, calories: 3 },
      ],
    }],
    evening: [{
      name: "Грибной крем-суп",
      totalCalories: 200,
      ingredients: [
        { name: "Грибы", grams: 200, calories: 44 },
        { name: "Лук", grams: 50, calories: 20 },
        { name: "Сливки низкой жирности", grams: 50, calories: 45 },
        { name: "Куриный бульон", grams: 200, calories: 20 },
        { name: "Специи", grams: 5, calories: 0 },
      ],
    }],
  },




  {
    morning: [{
      name: "Овсяноблин с ягодами",
      totalCalories: 300,
      ingredients: [
        { name: "Овсяная мука", grams: 50, calories: 180 },
        { name: "Яйцо", grams: 50, calories: 70 },
        { name: "Ягоды", grams: 100, calories: 40 },
        { name: "Натуральный йогурт", grams: 30, calories: 10 },
      ],
    }],
    noon: [{
      name: "Тушеные овощи с куриной грудкой",
      totalCalories: 350,
      ingredients: [
        { name: "Куриная грудка", grams: 150, calories: 165 },
        { name: "Цукини", grams: 100, calories: 17 },
        { name: "Баклажан", grams: 100, calories: 25 },
        { name: "Томатный соус", grams: 50, calories: 30 },
        { name: "Специи", grams: 5, calories: 0 },
      ],
    }],
    evening: [{
      name: "Салат с киноа и авокадо",
      totalCalories: 320,
      ingredients: [
        { name: "Киноа", grams: 50, calories: 180 },
        { name: "Авокадо", grams: 50, calories: 80 },
        { name: "Черри", grams: 50, calories: 15 },
        { name: "Лимонный сок", grams: 10, calories: 3 },
        { name: "Оливковое масло", grams: 10, calories: 90 },
      ],
    }],
  },

{
      morning: [{
        name: "Овсяноблин с ягодами",
        totalCalories: 300,
        ingredients: [
          { name: "Овсяная мука", grams: 50, calories: 180 },
          { name: "Яйцо", grams: 50, calories: 70 },
          { name: "Ягоды", grams: 100, calories: 40 },
          { name: "Натуральный йогурт", grams: 30, calories: 10 },
        ],
      }],
      noon: [{
        name: "Тушеные овощи с куриной грудкой",
        totalCalories: 350,
        ingredients: [
          { name: "Куриная грудка", grams: 150, calories: 165 },
          { name: "Цукини", grams: 100, calories: 17 },
          { name: "Баклажан", grams: 100, calories: 25 },
          { name: "Томатный соус", grams: 50, calories: 30 },
          { name: "Специи", grams: 5, calories: 0 },
        ],
      }],
      evening: [{
        name: "Салат с киноа и авокадо",
        totalCalories: 320,
        ingredients: [
          { name: "Киноа", grams: 50, calories: 180 },
          { name: "Авокадо", grams: 50, calories: 80 },
          { name: "Черри", grams: 50, calories: 15 },
          { name: "Лимонный сок", grams: 10, calories: 3 },
          { name: "Оливковое масло", grams: 10, calories: 90 },
        ],
      }],
    },
    // День 10
    {
      morning: [{
        name: "Смузи из шпината, банана и протеина",
        totalCalories: 280,
        ingredients: [
          { name: "Шпинат", grams: 50, calories: 12 },
          { name: "Банан", grams: 100, calories: 90 },
          { name: "Протеиновый порошок", grams: 30, calories: 120 },
          { name: "Вода", grams: 200, calories: 0 },
        ],
      }],
      noon: [{
        name: "Легкий салат с тунцом",
        totalCalories: 300,
        ingredients: [
          { name: "Консервированный тунец в собственном соку", grams: 100, calories: 99 },
          { name: "Салатные листья", grams: 50, calories: 7 },
          { name: "Огурец", grams: 50, calories: 8 },
          { name: "Помидор", grams: 50, calories: 11 },
          { name: "Лук красный", grams: 30, calories: 12 },
          { name: "Лимонный сок", grams: 10, calories: 3 },
          { name: "Оливковое масло", grams: 10, calories: 90 },
        ],
      }],
      evening: [{
        name: "Запеченная цветная капуста с специями",
        totalCalories: 200,
        ingredients: [
          { name: "Цветная капуста", grams: 300, calories: 75 },
          { name: "Куркума", grams: 5, calories: 15 },
          { name: "Паприка", grams: 5, calories: 14 },
          { name: "Чеснок порошок", grams: 5, calories: 10 },
          { name: "Оливковое масло", grams: 10, calories: 90 },
        ],
      }],
    },

    // День 13
{
    morning: [{
      name: "Ягодный смузи с овсянкой",
      totalCalories: 300,
      ingredients: [
        { name: "Свежие ягоды (любые)", grams: 100, calories: 40 },
        { name: "Овсянка", grams: 30, calories: 108 },
        { name: "Банан", grams: 50, calories: 45 },
        { name: "Альтернативное молоко (например, миндальное)", grams: 200, calories: 60 },
      ],
    }],
    noon: [{
      name: "Вегетарианские лепешки с овощами и хумусом",
      totalCalories: 350,
      ingredients: [
        { name: "Цельнозерновая лепешка", grams: 50, calories: 130 },
        { name: "Хумус", grams: 50, calories: 78 },
        { name: "Смесь овощей (баклажан, перец, лук)", grams: 150, calories: 60 },
        { name: "Листья салата", grams: 30, calories: 5 },
      ],
    }],
    evening: [{
      name: "Куриный бульон с нудлами и овощами",
      totalCalories: 250,
      ingredients: [
        { name: "Куриный бульон", grams: 500, calories: 40 },
        { name: "Куриное филе", grams: 100, calories: 110 },
        { name: "Цельнозерновые нудлы", grams: 50, calories: 35 },
        { name: "Морковь", grams: 50, calories: 21 },
        { name: "Брокколи", grams: 50, calories: 17 },
      ],
    }],
  },



  {
    morning: [{
      name: "Смузи из шпината, банана и протеина",
      totalCalories: 280,
      ingredients: [
        { name: "Шпинат", grams: 50, calories: 12 },
        { name: "Банан", grams: 100, calories: 90 },
        { name: "Протеиновый порошок", grams: 30, calories: 120 },
        { name: "Вода", grams: 200, calories: 0 },
      ],
    }],
    noon: [{
      name: "Легкий салат с тунцом",
      totalCalories: 300,
      ingredients: [
        { name: "Консервированный тунец в собственном соку", grams: 100, calories: 99 },
        { name: "Салатные листья", grams: 50, calories: 7 },
        { name: "Огурец", grams: 50, calories: 8 },
        { name: "Помидор", grams: 50, calories: 11 },
        { name: "Лук красный", grams: 30, calories: 12 },
        { name: "Лимонный сок", grams: 10, calories: 3 },
        { name: "Оливковое масло", grams: 10, calories: 90 },
      ],
    }],
    evening: [{
      name: "Запеченная цветная капуста с специями",
      totalCalories: 200,
      ingredients: [
        { name: "Цветная капуста", grams: 300, calories: 75 },
        { name: "Куркума", grams: 5, calories: 15 },
        { name: "Паприка", grams: 5, calories: 14 },
        { name: "Чеснок порошок", grams: 5, calories: 10 },
        { name: "Оливковое масло", grams: 10, calories: 90 },
      ],
    }],
  },
  ]
  
  
  
  export default nutritionData;